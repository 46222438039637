/* @import '/src/compStyles/colors.css'; */
/* @import '/src/compStyles/font.css'; */

@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {
  /* Color Variables */
  .gradient-50{
      background: linear-gradient(135deg, #E8F9F3 0%, #FFFBEC 100%);       
  }
  .gradient-100{
      background: linear-gradient(135deg, #D0F2E6 0%, #FFF8DA 100%);
  }
  .gradient-500{
      background: linear-gradient(135deg, #16C083 0%, #FFDA45 100%);
  }

  /* Fonts */
  
}

:root{

  /* Black + white */
  --white:#FFFFFF;
  --black:#002032;

  /* Gray */
  --gray-50:#F2F4F5
}

body {
  margin: 0;
  /* height: auto; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* PopUpMenuList */
.hideMenuNav {
  display: none;
}
.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: white;
  z-index: 10;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly;
  align-items: center; */
  
}



/* SearchInputIcon */

*{
  box-sizing: border-box;
}

.search-box{
  /* margin: 2px; */
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  position: absolute;
  height: 40px;
  width: 0px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 10px;
  transition: all .5s ease-in-out;
  background-color:white;
  /* padding-right: 40px; */
  color:gray;
  
}
.input-search::placeholder{
  color:rgba(255,255,255,.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btn-search{
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  /* position: absolute; */
  margin-top: -0.2rem;
  margin-left: 0.6rem; 
  /* right: 0px; */
  color:black;
  /* background-color:transparent; */
  pointer-events: painted;  
}
.btn-search:focus ~ .input-search{

  width: 150px;
  border-radius: 10px;
  /* margin-top: 10rem; */
  background-color: transparent;
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  margin-top: 2rem;
  width: 150px;
  border-radius: 10px;
  /* background-color: trans; */
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}


/* Candidate Listing */
.cand-layout{
  display:none;
}

.gradient{
  background: linear-gradient(180deg, rgba(255, 218, 69, 0.10) 0%, rgba(22, 192, 131, 0.10) 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;
}


/* Scrollbar hidden */

.no-scrollbar::-webkit-scrollbar {
  display: none;
}



